import React, { useState, useEffect } from "react";
import Axios from "axios";
import ApiConfig from "../../Configs/ApiConfig";
import { Col, Row, Spinner } from "react-bootstrap";

const DirectReferral = ({ getTeamSize }) => {
  const token = localStorage.getItem("token");
  const [count, setCount] = useState([]);
  const [loading, setLoading] = useState(true);

  // get directReferral count  api integration
  const directReferral = async (values) => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.numbersofCommunityMember,
        headers: { token: token },
      });

      if (res.data.statusCode === 200) {
        setCount([
          {
            item: "Card1",
            count: res.data.result.teamData.totalTeam || 0,
            name: "Total Team members",
          },
          {
            item: "Card2",
            count: res.data.result.teamData.activeTeam || 0,
            name: "Active members",
          },
          {
            item: "Card3",
            count: res.data.result.paamBusiness.toFixed(4) || 0,
            name: "Total Paam Business",
          },
        ]);
        setLoading(false);
        handleTeamSizeReturn(res.data.result);
        // setTotalProfit(res.data.result.totalProfit);
      } else {
        // toast.error("res.data.responseMessage");
      }
    } catch (error) {
      // toast.error(error.response.data.responseMessage);
    }
  };
  function handleTeamSizeReturn(data) {
    let percentForty = Math.floor(data?.team40);
    let percentSixty = Math.floor(data?.team60);
    let val = percentForty + percentSixty;
    getTeamSize(val, percentForty, percentSixty, data?.team60Head);
  }
  useEffect(() => {
    if (token) {
      directReferral();
    }
  }, [token]);

  return (
    <>
      <div>
        {loading ? (
          <div className="text-center mb-5">
            <Spinner />
          </div>
        ) : (
          <Row className="g-4 mb-4">
            {count?.length > 0 && count.map((values) => {
              return (
                <>
                  <Col sm={6} md={4}>
                    <div className="rewardDiv teamDiv">
                      <p className="mb-3">{values.name}</p>
                      <h3>{values?.count || "0"}</h3>
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
        )}
      </div>
    </>
  );
};

export default DirectReferral;
