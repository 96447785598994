import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { BiPaste } from "react-icons/bi";
import { FcOk } from "react-icons/fc";
// import ConfirmationDialog from "src/component/ConfirmationDialog";
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import MainContext from "../../Configs/Context/MainContext";
import ApiConfig from "../../Configs/ApiConfig";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../../Configs/Routes";
import CustomModal from "../../Components/CustomModal/CustomModal";
import ConfirmationModal from "./ConfirmationModal";

function roughScale(x, base) {
  const parsed = parseInt(x, base);
  if (isNaN(parsed)) {
    return 0;
  }
  return parsed;
}
const Transfer = () => {
  const token = localStorage.getItem("token");
  const user = useContext(MainContext);
  const navigate = useNavigate();
  const exchangeBalance = user?.exchangeBalance?.allExchangeTotal;
  // const [rightBar, setRightBar] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [verified, setVerified] = useState(true);
  const [otpVerified, setOtpVerified] = useState(false);
  const [loadingTrans, setLoadingTrans] = useState(false);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [address, setAddress] = useState("");
  const [brokerError, setBrokerError] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [otpField, setOtpField] = useState(true);
  const [otp, setOtp] = useState("");
  const [verifiedDetail, setVerifiedDetail] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [inputoff, setInputOff] = useState(false);
  const [verificationoff, setVerificationOff] = useState(true);

  const [broker, setBroker] = useState("");
  const [accountType, setAccountType] = useState("Paam");
  const [accountNumber, setAccountNumber] = useState("");

  // otp timer
  const minute = user.timeLeft?.minutes?.toString();
  const second = user.timeLeft?.seconds?.toString();

  // paste the text from clipboard function
  // function handlePaste() {
  //   navigator.clipboard
  //     .readText()
  //     .then((text) => setAddress(text))
  //     .catch((error) => console.error("Error reading clipboard:", error));
  // }
  useEffect(() => {
    user?.setEndtime(moment().add(0, "m").unix());
  }, []);
  // handling the amount
  const handleAmount = (e) => {
    const value = e.target.value;
    if (value.length == 0 || value == "") {
      setAmountError(true);
      setAmount("");
    } else {
      setError((prev) => {
        return { ...prev, amount: false };
      });
      setAmountError(false);
      setAmount(value);
    }
  };
  // handling the address
  // const handleAddress = (e) => {
  //   const value = e.target.value;
  //   if (value.length == 0 || value == "") {
  //     setAddError(true);
  //     setAddress("");
  //   } else {
  //     setAddError(false);
  //     setVerifiedDetail({});
  //     setAddress(value);
  //   }
  // };
  // handling the password
  const handlePassword = (value) => {
    if (value.length == 0 || value == "") {
      setPasswordErr(true);
      setPassword("");
    } else {
      setPasswordErr(false);
      setPassword(value);
    }
  };

  //   withdraw api integration
  const transferAsset = async () => {
    setLoadingOtp(true);
    if (amount && accountType)
      try {
        const res = await Axios({
          method: "POST",
          url: ApiConfig.transferAsset,
          headers: { token: token },
          data: {
            transferTo: accountType,
            amount,
          },
        });

        if (res.data.statusCode === 200) {
          setLoadingOtp(false);
          toast.success(res.data.responseMessage);
          setTransactionId(res.data.result._id);
          setOtpField(true);
          setInputOff(false);
          setVerificationOff(false);
          user?.setEndtime(moment().add(2, "m").unix());
          // setAccountAsset(res.data.result);
        } else {
          toast.error(res.data.responseMessage);
          setLoadingOtp(false);
        }
      } catch (error) {
        setLoadingOtp(false);
        if (error.response.data.statusCode == 500) {
          toast.error("Something went wrong!");
        } else {
          toast.error(error.response.data.responseMessage);
        }
      }
    else {
      if (!amount) {
        setAmountError(true);
        setError((prev) => {
          return { ...prev, amount: true };
        });
      }
      setLoadingOtp(false);
    }
  };

  const handelverify = (e) => {
    setOtp(e.target.value);
    const x = roughScale(e.target.value);

    roughScale(e.target.value);

    if (e.target.value.length === 6) {
      handleVerifyTransaction(x);
    } else {
    }
  };

  // verify transaction api integration
  const handleVerifyTransaction = async (value) => {
    setLoadingOtp(true);

    try {
      const res = await Axios({
        method: "PUT",
        url: ApiConfig.verifyTransaction,
        headers: { token: token },
        data: {
          _id: transactionId,
          otp: value,
        },
      });

      if (res.data.statusCode === 200) {
        toast.success(res.data.responseMessage);
        user?.setEndtime(moment().add(0, "m").unix());
        setOtpVerified(true);
        setLoadingOtp(false);
        setInputOff(true);
        setOtpField(false);
        setVerificationOff(true);
      } else {
        toast.error(res.data.responseMessage);
        setLoadingOtp(false);
      }
    } catch (error) {
      setLoadingOtp(false);
      if (error.response.data.responseMessage) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };
  // confirm transaction api interaction
  const confirmAssetTransaction = async (value) => {
    setLoadingTrans(true);

    try {
      const res = await Axios({
        method: "PUT",
        url: ApiConfig.confirmAssetTransaction,
        headers: { token: token },
      });

      if (res.data.statusCode === 200) {
        toast.success(res.data.responseMessage);
        setLoadingTrans(false);
        setAddress("");
        setAmount("");
        setPasswordErr("");
        setVerified(false);
        setOtp("");
        setPassword("");
        setAccountNumber("");
        setVerifiedDetail({});
        setOtpVerified(false);
        setOtpField(true);
        setOpen(false);
        setInputOff(false);
        // window.location.reload();
      }
    } catch (error) {
      setOpen(false);
      setLoadingTrans(false);
      if (error.response.data.responseMessage) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };

  const confirmationHandler = () => {
    setOpen(true);
  };

  // verify receiver address api integration
  // const checkReceiverAccount = async (value) => {
  //   setVerified(true);
  //   if (address) {
  //     try {
  //       const res = await Axios({
  //         method: "GET",
  //         url: ApiConfig.checkReceiverAccount,
  //         headers: { token: token },
  //         params: {
  //           address: address,
  //         },
  //       });

  //       if (res.data.statusCode === 200) {
  //         if (res.data.result.status == true) {
  //           setVerified(false);
  //           setVerifiedDetail(res.data.result);

  //           toast.success("Verified account.");
  //         } else {
  //           toast.warn("Not verified account.");
  //         }
  //       } else {
  //       }
  //     } catch (error) {
  //     }
  //   } else {
  //     toast.warn("Please enter the address.");
  //   }
  // };

  return (
    <>
      <div>
        <div className="withdrawDiv mb-4">
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <h6 className="mb-4">
              Chain :{" "}
              <span
                className="text-white py-1 px-2"
                style={{ background: "#42B57C", borderRadius: "10px" }}
              >
                BEP20
              </span>
            </h6>
            {/* <h6>
              Available Balance:{" "}
              {exchangeBalance ? exchangeBalance?.toFixed(4) : "0.00"} USDT
            </h6> */}
          </div>

          <Row className="gy-3 mb-3">
            {/* <Col md={6}>
              <label>Broker</label>
              <Form.Control
                type="text"
                placeholder="Please enter broker"
                onChange={(e) => {
                  setBroker(e.target.value);
                }}
                value={broker}
                disabled={inputoff}
                maxLength={256}
              />
            </Col> */}
            {/* {brokerError === true && (<p style={{ fontSize: "12px", color: "red", }}>Please Enter Broker.</p>)} */}

            <Col md={6}>
              <label>Account Type</label>
              <Form.Select
                onChange={(e) => {
                  setAccountType(e.target.value);
                }}
                value={accountType}
                disabled={inputoff}
              >
                {/* <option value="mt4">MT4</option>
                <option value="mt5">MT5</option> */}
                <option value="Paam">Paam Account</option>
              </Form.Select>
              {/* {error?.accountType === true && (<p style={{ fontSize: "12px", color: "red", }}>Please Select Account Type.</p>)} */}
         
            </Col>

            <Col md={6}>
              <label>Forex Account Number [optional]</label>
              <Form.Control
                type="number"
                placeholder="Enter account number"
                value={accountNumber}
                disabled={inputoff}
                maxLength={256}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
              {/* {error?.amount === true && (<p style={{ fontSize: "12px", color: "red", }}>Please Select Account Number.</p>)} */}
            </Col>

            <Col md={6}>
            <div
                className="d-flex flex-wrap align-items-center justify-content-between "
                
              >
                  <label>Amount</label>
                <p className="text-secondary" style={{ fontSize: "14px" }}>
                {"Available Balance: "}{exchangeBalance ? exchangeBalance?.toFixed(4) : "0.00"} USDT
                </p>

              </div>
            
              <InputGroup>
                <Form.Control
                  type="number"
                  placeholder="Enter transfer amount"
                  value={amount}
                  disabled={inputoff}
                  maxLength={256}
                  onChange={(e) => handleAmount(e)}
                />
                <InputGroup.Text>
                  <Button onClick={() => setAmount(exchangeBalance)}>
                    MAX
                  </Button>
                </InputGroup.Text>
              </InputGroup>
              {/* <div className="d-flex flex-wrap align-items-center justify-content-between text-secondary" style={{ fontSize: '14px' }}>
                <p>Arrival: {amount ? parseFloat(amount)?.toFixed(4) : 0.0} USDT</p>
              </div> */}
              {error?.amount === true && (
                <p style={{ fontSize: "12px", color: "red" }}>
                  Please enter the amount.
                </p>
              )}
            </Col>

            <Col md={6}>
              <label>Verification Code</label>
              <InputGroup>
                <Form.Control
                  type="number"
                  placeholder="Please enter verification code"
                  onChange={(e) => handelverify(e)}
                  value={otp}
                  disabled={verificationoff}
                  maxLength={6}
                />
                <InputGroup.Text>
                  {otpVerified == false ? (
                    <>
                      {user.timeLeft?.minutes > 0 ||
                      user.timeLeft?.seconds > 0 ? (
                        <>
                          <Button disabled>
                            {minute.length > 1 ? minute : "0" + minute} :{" "}
                            {second.length > 1 ? second : "0" + second}
                          </Button>
                        </>
                      ) : (
                        <Button
                          disabled={amountError || loadingOtp}
                          onClick={() => transferAsset()}
                        >
                          {loadingOtp ? (
                            <Spinner size="sm" style={{ borderWidth: "2px" }} />
                          ) : (
                            "Send code"
                          )}
                        </Button>
                      )}
                    </>
                  ) : (
                    <FcOk />
                  )}
                </InputGroup.Text>
              </InputGroup>
            </Col>

            {/* <Col md={6}>
              <label>Transaction Password</label>
              <InputGroup>
                <Form.Control type={showPassword ? "text" : "password"} placeholder="Enter your password" onChange={(e) => handlePassword(e.target.value)} value={password} maxLength={16} />
                <InputGroup.Text>
                  <div onClick={() => setShowPassword(!showPassword)} style={{ fontSize: '22px', color: '#838383' }}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </div>
                </InputGroup.Text>
              </InputGroup>
              <div className="text-end" style={{ fontSize: '14px', color: '#42B57C', cursor: 'pointer', }}>
                <Link to={ROUTES.SECURITY} >Change Transaction password</Link>
              </div>
            </Col> */}
          </Row>

          {/* {verified ? (
            <Button className="withdrawBtn" onClick={() => toast.error("Please verify the account address.")} disabled={loadingTrans || passwordErr || otpField}>Confirm Transfer</Button>
          ) : ( */}
          <Button
            className="withdrawBtn"
            onClick={confirmationHandler}
            disabled={loadingTrans || passwordErr || otpField}
          >
            {loadingTrans ? (
              <Spinner size="sm" style={{ borderWidth: "2px" }} />
            ) : (
              "Confirm Transfer"
            )}
          </Button>
          {/* )} */}
        </div>

        <div>
          <h5>Precautions:</h5>
          <ol className="ps-3" style={{ color: "#838383", fontSize: "14px" }}>
            <li>
              Please do not deposit any non-USDT assets to the address above.
            </li>
            <li>Ensure you use BEP20 to deposit, or assets might be lost.</li>
            <li>The deposit may take a short while to arrive.</li>
            <li>Funds may not be withdrawn from inactive accounts.</li>
          </ol>
        </div>
      </div>

      <CustomModal
        display={open}
        handleClose={() => setOpen(false)}
        size="md"
        className="confirmModal"
        centered
      >
        <ConfirmationModal
          handleClose={() => setOpen(false)}
          title={"Confirm"}
          desc={"Are you sure you want to proceed with this transaction?"}
          confirmationHandler={confirmAssetTransaction}
          loading={loadingTrans}
        />
      </CustomModal>
    </>
  );
};

export default Transfer;
