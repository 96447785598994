import React, { useState, useMemo } from "react";
import Axios from "axios";
import { FaEye } from "react-icons/fa";
import ApiConfig from "../../Configs/ApiConfig";
import { Spinner, Table } from "react-bootstrap";
import ReferralView from "./ReferralView";

const ReferTeam = () => {
  const token = localStorage.getItem("token");
  const [refferalDetails, setRefferalDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [showDetails, setShowDetails] = useState(false);

  const getReferralDetails = async () => {
    setLoading(true);
    const params = {
      limit: 10,
      page: 1,
      // userId: '6527e6cebad98dac79b1f99b'
    };
    const res = await Axios({
      method: "GET",
      url: ApiConfig.referralDetails,
      headers: { token: token },
      params: params,
    });
    if (res.data.statusCode === 200) {
      setRefferalDetails(res.data.result);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useMemo(() => {
    if (token) {
      getReferralDetails();
    }
  }, [token]);

  return (
    <>
      <div>
        {!showDetails ?
          <div>
            {loading ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : (
              <>
                {refferalDetails?.length !== 0 ? (
                  <Table responsive className="forexTable">
                    <thead>
                      <tr>
                        <th>Level</th>
                        <th>Total members</th>
                        {/* <th>Total BV</th> */}
                        <th>Active</th>
                        <th>Paam Business</th>
                        {/* <th>Inactive</th> */}
                        <th>Preview</th>
                      </tr>
                    </thead>
                    {console.log(refferalDetails,"refferalDetails")}
                    <tbody>
                      {refferalDetails &&
                        refferalDetails.map((values, index) => {
                          return (
                            <tr key={`tableRow-${index}`}>
                              <td>{values.level}</td>
                              <td>{(values.totalActiveTeam || 0) + (values.totalInactiveTeam || 0)}</td>
                              <td>{values.totalActiveTeam || 0}</td>
                              <td>{values.totalPaamBalance || 0}</td>
                              <td style={{ color: '#42B57C', fontSize: '20px' }}>
                                {/* <Icons level={values.level} /> */}
                                <FaEye onClick={() => { setSelectedLevel(values.level); setShowDetails(true); }} />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                ) : (
                  <div className="text-center">
                    <p>No Data Found</p>
                  </div>
                )}
              </>
            )}
          </div>
          :
          <ReferralView selectedLevel={selectedLevel} setSelectedLevel={setSelectedLevel} setShowDetails={setShowDetails} />
        }

      </div>
    </>
  );
};

export default ReferTeam;
