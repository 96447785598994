import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BiPaste } from "react-icons/bi";
// import ConfirmationDialog from "src/component/ConfirmationDialog";
import { FcOk } from "react-icons/fc";
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import ApiConfig from "../../Configs/ApiConfig";
import MainContext from "../../Configs/Context/MainContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../../Configs/Routes";
import CustomModal from "../../Components/CustomModal/CustomModal";
import ConfirmationModal from "./ConfirmationModal";

function roughScale(x, base) {
  const parsed = parseInt(x, base);
  if (isNaN(parsed)) {
    return 0;
  }
  return parsed;
}

const Withdraw = ({ selected }) => {
  const token = localStorage.getItem("token");
  const user = useContext(MainContext);
  const navigate = useNavigate();
  const exchangeBalance =
    selected == "system"
      ? user?.exchangeBalance?.allExchangeTotal
      : user?.exchangeBalance?.paamBalance;
  // const [rightBar, setRightBar] = useState(false);

  const [loadingOtp, setLoadingOtp] = useState(false);
  const [verified, setVerified] = useState(false);
  const [loadingTrans, setLoadingTrans] = useState(false);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [amountlengthError, setAmountLengthError] = useState(false);
  const [largAmountlengthError, setLargAmountLengthError] = useState(false);
  const [addError, setAddError] = useState(false);
  const [otpField, setOtpField] = useState(true);
  const [address, setAddress] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [otp, setOtp] = useState("");
  const [inputoff, setInputOff] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [verificationoff, setVerificationOff] = useState(true);
  const [open, setOpen] = useState(false);
  // otp timer
  const minute = user.timeLeft?.minutes?.toString();
  const second = user.timeLeft?.seconds?.toString();

  // paste the text from clipboard function
  function handlePaste() {
    navigator.clipboard
      .readText()
      .then((text) => setAddress(text))
      .catch((error) => console.error("Error reading clipboard:", error));
  }

  // get balance
  useEffect(() => {
    setOtp("")
    setPassword("")
    setTransactionId("")
    setAddress("")
    setAmount("")
  }, [selected]);
  useEffect(() => {
    user?.setEndtime(moment().add(0, "m").unix());
  }, []);

  const handleAmount = (e) => {
    const value = e.target.value;
    if (value.length == 0 || value == "") {
      setAmountError(true);
      setAmountLengthError(false);
      setLargAmountLengthError(false);
      setAmount("");
    } else {
      if (value > exchangeBalance) {
        setLargAmountLengthError(true);
        setAmount(value);
        setAmountError(false);
        setAmountLengthError(false);
      } else if (value < exchangeBalance) {
        setLargAmountLengthError(false);
        setAmount(value);
        setAmountError(false);
        setAmountLengthError(false);
      } else {
        setAmountError(false);
        setAmountLengthError(false);
        setAmount(value);
      }
    }
  };

  const handleAddress = (e) => {
    const value = e.target.value;
    if (value.length == 0 || value == "") {
      setAddError(true);
      setAddress("");
    } else {
      setAddError(false);
      setAddress(value);
    }
  };
  const handlePassword = (value) => {
    if (value.length == 0 || value == "") {
      setPasswordErr(true);
      setPassword("");
    } else {
      setPasswordErr(false);
      setPassword(value);
    }
  };
  const handleWithdraw = async () => {
    let data = null;
    if (selected == "system") {
      if (!amount) {
        setAmountError(true);
        return;
      }
      if (!address) {
        setAddError(true);
        return;
      }
      data = {
        chainName: "BEP-20",
        amount: amount,
        walletAddress: address,
        withdrawFrom: "web3",
      };
    } else {
      if (!amount) {
        setAmountError(true);
        return;
      }
      data = {
        amount: amount,
        withdrawFrom: "Paam",
      };
    }

    try {
      setLoadingOtp(true);
      const res = await Axios({
        method: "POST",
        url: ApiConfig.WithdrawAsset,
        headers: { token: token },
        data,
      });

      if (res?.data?.statusCode === 200) {
        user?.setEndtime(moment().add(2, "m").unix());
        setLoadingOtp(false);
        toast.success(res?.data?.responseMessage);
        setTransactionId(res?.data?.result?._id);
        setOtpField(true);
        setInputOff(true);
        setVerificationOff(false);
        // setAccountAsset(res?.data?.result);
        exchangeBalance();
      } else {
        toast.error(res?.data?.responseMessage);
        setLoadingOtp(false);
      }
    } catch (error) {
      setLoadingOtp(false);
      if (error.response?.data?.statusCode == 500) {
        toast.error("Something went wrong!");
      } else {
        toast.error(error?.response?.data?.responseMessage);
      }
    }
  };

  const handelverify = (e) => {
    const x = roughScale(e.target.value);
    setOtp(e.target.value);
    roughScale(e.target.value);

    if (e.target.value.length === 6) {
      handleVerifyTransaction(x);
    } else {
    }
  };

  // verify transaction api integration
  const handleVerifyTransaction = async (value) => {
    try {
      const res = await Axios({
        method: "PUT",
        url: ApiConfig.verifyTransaction,
        headers: { token: token },
        data: {
          _id: transactionId,
          otp: value,
        },
      });

      if (res?.data?.statusCode === 200) {
        toast.success(res?.data?.responseMessage);
        // setAccountAsset(res?.data?.result);
        user?.setEndtime(moment().add(0, "m").unix());
        setVerified(true);
        setLoadingOtp(false);
        setInputOff(true);
        setVerificationOff(true);
        setOtpField(false);
      } else {
        toast.error(res?.data?.responseMessage);
        setLoadingOtp(false);
      }
    } catch (error) {
      setLoadingOtp(false);
      if (error.response?.data?.responseMessage) {
        toast.error(error.response?.data?.responseMessage);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };
  const confirmAssetTransaction = async (value) => {
    setLoadingTrans(true);

    try {
      const res = await Axios({
        method: "PUT",
        url: ApiConfig.confirmAssetTransaction,
        headers: { token: token },
        data: {
          _id: transactionId,
          transactionPassword: password,
        },
      });

      if (res?.data?.statusCode === 200) {
        toast.success(
          "Your withdrawal request has been sent to the admin. Please check after 30 minitues."
        );
        setLoadingTrans(false);
        setAddress("");
        setAmount("");
        setPasswordErr("");
        setVerified(false);
        setOtp("");
        setPassword("");
        setOpen(false);
        setInputOff(false);
      }
    } catch (error) {
      setLoadingTrans(false);
      setOpen(false);
      if (error.response?.data?.responseMessage) {
        toast.error(error.response?.data?.responseMessage);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };
  const confirmationHandler = () => {
    setOpen(true);
  };

  return (
    <>
      <div>
        <div className="withdrawDiv mb-4">
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            {selected == "system" && (
              <h6 className="mb-4">
                Chain :{" "}
                <span
                  className="text-white py-1 px-2"
                  style={{ background: "#42B57C", borderRadius: "10px" }}
                >
                  BEP20
                </span>
              </h6>
            )}
            {/* <h6>
              {selected == "system" ? "Available Balance: " : "Paam Balance: "}
              {exchangeBalance ? exchangeBalance?.toFixed(4) : "0.00"} USDT
            </h6> */}
          </div>

          <Row className="gy-3 mb-3">
            {selected == "system" && (
              <Col md={6}>
                <label>Address</label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Please enter address"
                    onChange={(e) => handleAddress(e)}
                    value={address}
                    disabled={inputoff}
                    maxLength={256}
                  />
                  <InputGroup.Text>
                    <Button onClick={() => handlePaste()}>
                      <BiPaste />
                    </Button>
                  </InputGroup.Text>
                </InputGroup>
                {addError === true && (
                  <p style={{ fontSize: "12px", color: "red" }}>
                    Please enter the address.
                  </p>
                )}
            
              
              </Col>

            )}

            <Col md={6}>
           
              <div
                className="d-flex flex-wrap align-items-center justify-content-between"
               
              >
                   <label>Amount</label>
                <p  className="text-secondary" style={{ fontSize: "14px" }}>
                {selected == "system" ? "Available Balance: " : "Paam Balance: "} {exchangeBalance ? exchangeBalance?.toFixed(4) : "0.00"} USDT
                </p>

              </div>
              <InputGroup>
                <Form.Control
                  type="number"
                  placeholder="Enter amount"
                  value={amount}
                  disabled={inputoff}
                  maxLength={256}
                  onChange={(e) => handleAmount(e)}
                // onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 15); }}
                />
                <InputGroup.Text>
                  <Button
                    onClick={() => setAmount(exchangeBalance?.toFixed(4))}
                  >
                    MAX
                  </Button>
                </InputGroup.Text>
              </InputGroup>
              <div
                className="d-flex flex-wrap align-items-center justify-content-between text-secondary"
                style={{ fontSize: "14px" }}
              >
                <p>
                  Arrival: {amount ? parseFloat(amount)?.toFixed(4) - 1 : 0.0}{" "}
                  USDT
                </p>
                <p>Transaction Fee: 1 USDT</p>
              </div>
              {amountError === true && (
                <p style={{ fontSize: "12px", color: "red" }}>
                  Please enter the amount.
                </p>
              )}
              {largAmountlengthError === true && (
                <p style={{ fontSize: "12px", color: "red" }}>
                  Insufficient balance
                </p>
              )}
            </Col>

            <Col md={6}>
              <label>Verification Code</label>
              <InputGroup>
                <Form.Control
                  type="number"
                  placeholder="Please enter verification code"
                  onChange={(e) => handelverify(e)}
                  value={otp}
                  disabled={verificationoff}
                  maxLength={6}
                />
                <InputGroup.Text>
                  {verified === false ? (
                    user.timeLeft?.minutes > 0 || user.timeLeft?.seconds > 0 ? (
                      <Button disabled>
                        {minute.length > 1 ? minute : "0" + minute} :{" "}
                        {second.length > 1 ? second : "0" + second}
                      </Button>
                    ) : (
                      <Button
                        disabled={amountError || addError || loadingOtp}
                        onClick={() => handleWithdraw()}
                      >
                        {loadingOtp ? (
                          <Spinner size="sm" style={{ borderWidth: "2px" }} />
                        ) : (
                          "Send code"
                        )}
                      </Button>
                    )
                  ) : (
                    <FcOk />
                  )}
                </InputGroup.Text>
              </InputGroup>
            </Col>

            {/* <Col md={6}>
              <label>Transaction Password</label>
              <InputGroup>
                <Form.Control type={showPassword ? "text" : "password"} placeholder="Enter your password" onChange={(e) => handlePassword(e.target.value)} value={password} maxLength={16} />
                <InputGroup.Text>
                  <div onClick={() => setShowPassword(!showPassword)} style={{ fontSize: '22px', color: '#838383' }}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </div>
                </InputGroup.Text>
              </InputGroup>
              <div className="text-end" style={{ fontSize: '14px', color: '#42B57C', cursor: 'pointer', }}>
                <Link to={ROUTES.SECURITY} >Change Transaction password</Link>
              </div>
            </Col> */}
          </Row>

          <Button
            className="withdrawBtn"
            onClick={() => confirmationHandler()}
            disabled={loadingTrans || passwordErr || otpField}
          >
            {loadingTrans ? (
              <>
                <Spinner size="sm" style={{ borderWidth: "2px" }} />
              </>
            ) : (
              "Confirm Withdrawal"
            )}
          </Button>
        </div>

        <div>
          <h5>Operation Remainder :</h5>
          <div style={{ color: "#838383", fontSize: "14px" }}>
            <p>
              Please ensure that you do not transfer USD assets to addresses
              other than USDT(BEP-20), as it will not be recoverable.
            </p>
            <p>
              Withdrawal processing time: 30-60 minutes for blockchain
              confirmation.
            </p>
          </div>
        </div>
      </div>

      <CustomModal
        display={open}
        handleClose={() => setOpen(false)}
        size="md"
        className="confirmModal"
        centered
      >
        <ConfirmationModal
          handleClose={() => setOpen(false)}
          title={"Confirm"}
          desc={"Are you sure you want to proceed with this transaction?"}
          confirmationHandler={confirmAssetTransaction}
          loading={loadingTrans}
        />
      </CustomModal>

      {/* <Dialog
        classes={{ paper: 'desktopDrawer' }}
        open={rightBar}
        onClose={() => {
          setRightBar(false);
        }}
      ></Dialog> */}
      {/* {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"Confirm"}
          desc={"Are you sure you want to proceed with this transaction?"}
          confirmationHandler={confirmAssetTransaction}
        />
      )} */}
    </>
  );
};

export default Withdraw;
